import { Component, DestroyRef, inject } from '@angular/core';
import { IconService } from './core/services/icon.service';
import { MessageService } from './core/services/message.service';
import { AuthHttpService } from './core/services/auth-http.service';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserActivityService } from './core/services/user-activity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private destroyedRef = inject(DestroyRef);

  constructor(
    private iconService: IconService,
    private authHttpService: AuthHttpService,
    private messageService: MessageService,
    private userActivityService: UserActivityService,
  ) {
    this.iconService.registerSvgIcons();

    this.authHttpService
      .getIsUserConnected()
      .pipe(
        tap((response) => {
          if (response) {
            this.messageService.initWebSocketConnection();
            this.userActivityService.startMonitoringUserActivity();
          } else {
            this.messageService.closeWebSocketConnection();
          }
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe();
  }
}
